
import { Component, Mixins } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import XForm from "../SimpleForm.vue";
import XButton from "../SimpleButton.vue";
import ButtonWithMessage from "./ButtonWithMessage.vue";
import AppApiMixin from "../mixins/AppApi.vue";
import { signInForm } from "@/assets/scripts/form-fields/main";
import VueRecaptcha from 'vue-recaptcha';
import {mapGetters, mapMutations} from "vuex";

const { values, attrs, validations, errors } = signInForm;

@Component({
  components: {
    XForm,
    XButton,
    ButtonWithMessage,
    VueRecaptcha
  },
  mixins: [validationMixin],
  computed: {
    ...errors,
    ...mapGetters('appConfig', {
      appConfigDemo: 'demo',
      appConfigLogin: 'login',
      appConfigPassword: 'password',
    }),
    ...mapGetters({
      branchesList: "branches/branchesList",
      branchesListFormatted: "branches/branchesListFormatted",
    }),
  },
  validations,
  methods: {
    ...mapMutations("error", { setMessage: "setMessage" }),
  },
})
class SignInForm extends Mixins(XForm, AppApiMixin) {
  [x: string]: any;
  values = values;
  allFieldAttrs = attrs;

  showPassword = false;
  useSubmitButtonLoading = false;
  submitError = "";

  siteKey = ''
  recaptchaEnabled = false;
  mounted() {
    this.siteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY || ''
    this.recaptchaEnabled = (process.env.VUE_APP_RECAPTCHA_ENABLED || '') === "true"

    // список филиалов
    this.getBranches();

    // для демо кабинета
    if (this.appConfigDemo) {
      this.values['username'] = this.appConfigLogin;
      this.values['password'] = this.appConfigPassword;
    }
  }

  public onCaptchaExpired () {
    //@ts-ignore
    if (this.recaptchaEnabled) {
      this.$refs.recaptcha.reset()
    }
  }

  /*register(token: string) {
    console.log('register', token)
  }*/

  openPasswordRecoveryPage(): void {
    this.redirectToPage("passwordRecovery");
  }

  openSignUpPage(): void {
    this.redirectToPage("signUp");
  }

  public validate() {
    //@ts-ignore
    if (this.recaptchaEnabled) {
      this.$refs.recaptcha.execute()
    } else {
      this.submitForm('')
    }
  }

  submitForm(recaptchaToken: string) {
    this.submitError = "";
    this.useSubmitButtonLoading = true;

    const { username, password, branch } = this.getValues();

    const current_branch = this.branchesList.filter(_ => _.full_name == branch).map(_ => _.name)[0] || "";

    this.signIn({ username, password, branch: current_branch, recaptchaToken })
      .then(() => {
        localStorage.setItem('branch', branch);
      })
      .catch(async (e) => {
        this.submitError = e;
        //@ts-ignore
        if (this.recaptchaEnabled) {
          await this.$refs.recaptcha.reset()
        }
      })
      .finally(() => {
        this.useSubmitButtonLoading = false;
      });
  }

  get paymentNoAuthEnabled(): boolean {
    return !!(process.env && (process.env.VUE_APP_BANK_NO_AUTH || "") == "true")
  }
}

export default SignInForm;
